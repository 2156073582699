import React, { useState } from 'react';
import './PasswordRecoveryForm.css';
import { recoveryRequest, validateUserPasswordRecoveryCode, resetUserPassword } from '../../api_utils/auth_service';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;

const PasswordRecoveryForm = () => {
  const [email, setEmail] = useState('');
  const [recoveryCode, setRecoveryCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleRecoveryRequest = async (e) => {
    e.preventDefault();
    const trimmedEmail = email.trim();

    if (!trimmedEmail) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    try {
      await recoveryRequest(trimmedEmail);
      setStep(2);
      setErrorMessage(''); // Clear the error message if successful
    } catch (error) {
      setErrorMessage('Failed to send recovery email. Please try again.');
    }
  };

  const handleValidateCode = async (e) => {
    e.preventDefault();
    const trimmedEmail = email.trim();
    const trimmedRecoveryCode = recoveryCode.trim();

    if (!trimmedRecoveryCode) {
      setErrorMessage('Please enter the recovery code.');
      return;
    }

    try {
      await validateUserPasswordRecoveryCode(trimmedEmail, trimmedRecoveryCode);
      setStep(3);
      setErrorMessage(''); // Clear the error message if successful
    } catch (error) {
      setErrorMessage('Invalid recovery code. Please try again.');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const trimmedEmail = email.trim();
    const trimmedRecoveryCode = recoveryCode.trim();
    const trimmedNewPassword = newPassword.trim();

    if (!passwordRegex.test(trimmedNewPassword)) {
      setErrorMessage(
        'Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.'
      );
      return;
    }

    try {
      await resetUserPassword(trimmedEmail, trimmedRecoveryCode, trimmedNewPassword);
      navigate('/login'); // Redirect to the login page after password reset
    } catch (error) {
      setErrorMessage('Failed to reset password. Please try again.');
    }
  };

  return (
    <div className="container">
      <form noValidate onSubmit={step === 1 ? handleRecoveryRequest : step === 2 ? handleValidateCode : handleResetPassword}>
        <h2>Password Recovery</h2>

        {step === 1 && (
          <div className={`form-group ${!email && errorMessage ? 'error-field' : ''}`}>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        )}

        {step === 2 && (
          <div className={`form-group ${!recoveryCode && errorMessage ? 'error-field' : ''}`}>
            <label htmlFor="recoveryCode">Recovery Code:</label>
            <input
              type="text"
              id="recoveryCode"
              value={recoveryCode}
              onChange={(e) => setRecoveryCode(e.target.value)}
              required
            />
          </div>
        )}

        {step === 3 && (
          <div className={`form-group password-group ${!passwordRegex.test(newPassword) && errorMessage ? 'error-field' : ''}`}>
            <label htmlFor="newPassword">New Password:</label>
            <div className="password-wrapper">
              <input
                type={showPassword ? 'text' : 'password'}
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>
          </div>
        )}

        {errorMessage && <p className="error">{errorMessage}</p>}

        <button type="submit">
          {step === 1 ? 'Send Recovery Email' : step === 2 ? 'Validate Code' : 'Reset Password'}
        </button>
      </form>
    </div>
  );
};

export default PasswordRecoveryForm;