import React, { useRef, useState } from 'react';
import './LoginForm.css';
import { authTwoFactorUser, verifyTwoFactorCodeAndGenerateToken } from '../../api_utils/auth_service';
import { FaEye } from 'react-icons/fa';
import { FaEyeSlash } from 'react-icons/fa';

const LOGIN_TITLE = 'Login';
const EMAIL_LABEL = 'Email:';
const PASSWORD_LABEL = 'Password:';
const LOGIN_BUTTON = 'Login';
const CODE_LABEL = '2FA Code:';
const REGISTER_PROMPT = "Don't have an account?";
const REGISTER_LINK_TEXT = 'Register here';
const FORGOT_PASSWORD_TEXT = 'Forgot password?';
const VERIFY_BUTTON = 'Verify';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1); // Step 1: Login, Step 2: 2FA Code

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const codeRef = useRef(null);

  const handleLoginSubmit = async e => {
    e.preventDefault();

    if (email && password.length > 4) {
      try {
        await authTwoFactorUser(email, password);
        setStep(2);
      } catch (error) {
        setErrorMessage('Failed to login. Please check your credentials.');
      }
    } else {
      setErrorMessage('Invalid email or password');
    }
  };

  const handleCodeSubmit = async e => {
    e.preventDefault();

    if (code.length === 6) {
      try {
        const token = await verifyTwoFactorCodeAndGenerateToken(email, code);
        console.log('handleCodeSubmit> token:', token );
        if (token) {
          window.location.href = '/';
        }
      } catch (error) {
        setErrorMessage('Invalid or expired 2FA code. Please try again.');
      }
    } else {
      setErrorMessage('2FA code must be 6 digits long');
    }
  };

  return (
    <div className="container">
      <form noValidate onSubmit={step === 1 ? handleLoginSubmit : handleCodeSubmit}>
        <h2>{LOGIN_TITLE}</h2>

        {step === 1 && (
          <>
            <div className={`form-group ${!email && errorMessage ? 'error-field' : ''}`}>
              <label htmlFor="email">{EMAIL_LABEL}</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
                ref={emailRef}
              />
            </div>

            <div className={`form-group password-group ${password.length <= 4 && errorMessage ? 'error-field' : ''}`}>
              <label htmlFor="password">{PASSWORD_LABEL}</label>
              <div className="password-wrapper">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required
                  ref={passwordRef}
                />
                <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
            </div>
          </>
        )}

        {step === 2 && (
          <div className={`form-group ${code.length !== 6 && errorMessage ? 'error-field' : ''}`}>
            <label htmlFor="code">{CODE_LABEL}</label>
            <input
              type="text"
              id="code"
              value={code}
              onChange={e => setCode(e.target.value)}
              required
              ref={codeRef}
              maxLength={6}
            />
          </div>
        )}

        {errorMessage && <p className="error">{errorMessage}</p>}

        <button type="submit">{step === 1 ? LOGIN_BUTTON : VERIFY_BUTTON}</button>

        <p className="additional-links">
          <a href="/password-recovery">{FORGOT_PASSWORD_TEXT}</a>
        </p>

        <p>
          {REGISTER_PROMPT} <a href="/register">{REGISTER_LINK_TEXT}</a>
        </p>
      </form>
    </div>
  );
};

export default LoginForm;
