import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneNumberInput = ({ value, onChange, hasError }) => {

  const handlePhoneChange = (inputValue) => {
    const formattedValue = inputValue.startsWith('+') ? inputValue : `+${inputValue}`;
    onChange(formattedValue);
  };

  return (
    <div className={`form-group ${hasError ? 'error-field' : ''}`}>
      <label htmlFor="phone_number">Phone Number</label>
      <PhoneInput
        value={value}
        onChange={handlePhoneChange}
        inputProps={{
          name: 'phone_number',
          required: true,
          autoFocus: true,
        }}
        country={'us'}
        enableAreaCodes={true}
        enableSearch={true}
        countryCodeEditable={false}
      />
    </div>
  );
};

export default PhoneNumberInput;