import React, { useEffect, useRef, useState } from 'react';
import './RegisterForm.css';
import { registerUser } from '../../api_utils/auth_service';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';
import { FaEye } from 'react-icons/fa';
import { FaEyeSlash } from 'react-icons/fa';

const phoneRegex = /^\+[1-9]\d{1,14}$/;

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    date_of_birth: '',
    password: '',
    confirmPassword: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const dateOfBirthRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  // useEffect(() => {
  //   if (errorMessage) {
  //     if (!formData.first_name) {
  //       firstNameRef.current.focus();
  //     } else if (!formData.last_name) {
  //       lastNameRef.current.focus();
  //     } else if (!formData.email) {
  //       emailRef.current.focus();
  //     }else if (!formData.date_of_birth) {
  //       dateOfBirthRef.current.focus();
  //     } else if (formData.password !== formData.confirmPassword) {
  //       confirmPasswordRef.current.focus();
  //     } else if (formData.password.length <= 4) {
  //       passwordRef.current.focus();
  //     }
  //   }
  // }, [errorMessage, formData]);

  useEffect(() => {
    const allFieldsFilled =
      formData.first_name.trim() &&
      formData.last_name.trim() &&
      formData.email.trim() &&
      formData.phone_number.trim() &&
      formData.date_of_birth.trim() &&
      formData.password.trim() &&
      formData.confirmPassword.trim();

    setIsSubmit(allFieldsFilled);
  }, [formData]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const formatDate = date => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const handlePhoneChange = value => {
    setFormData({ ...formData, phone_number: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const trimmedFormData = {
      first_name: formData.first_name.trim(),
      last_name: formData.last_name.trim(),
      email: formData.email.trim(),
      phone_number: formData.phone_number.trim(),
      date_of_birth: formData.date_of_birth.trim(),
      password: formData.password.trim(),
      confirmPassword: formData.confirmPassword.trim(),
    };

    if (
      !trimmedFormData.first_name ||
      !trimmedFormData.last_name ||
      !trimmedFormData.email ||
      !trimmedFormData.password
    ) {
      setErrorMessage('Please fill out all required fields');
      return;
    }

    if (!phoneRegex.test(trimmedFormData.phone_number)) {
      setErrorMessage('Phone number must be in international format (e.g., +123456789)');
      return;
    }

    console.log('trimmedFormData.date_of_birth: ', trimmedFormData.date_of_birth);

    console.log('isValidAge > ', !isValidAge(trimmedFormData.date_of_birth));

    console.log('1 isValidAge > ');
    if (!isValidAge(trimmedFormData.date_of_birth)) {
      setErrorMessage('You must be at least 18 years old to register.');
      return;
    }

    if (!passwordRegex.test(trimmedFormData.password)) {
      setErrorMessage(
        'Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.'
      );
      return;
    }

    if (trimmedFormData.password !== trimmedFormData.confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      const formattedDate = formatDate(trimmedFormData.date_of_birth);
      const token = await registerUser({
        first_name: trimmedFormData.first_name,
        last_name: trimmedFormData.last_name,
        email: trimmedFormData.email,
        phone_number: trimmedFormData.phone_number,
        date_of_birth: formattedDate,
        password: trimmedFormData.password,
      });

      if (token) {
        window.location.href = '/';
      }
    } catch (error) {
      setErrorMessage('Failed to login');
    }
  };

  const isValidAge = dateOfBirth => {
    console.log('dateOfBirth: ', dateOfBirth);

    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    console.log('isValidAge: ', age >= 18);

    return age >= 18;
  };

  return (
    <div className="container">
      <form noValidate onSubmit={handleSubmit}>
        <h2>Register</h2>

        <div className={`form-group ${!formData.first_name && errorMessage ? 'error-field' : ''}`}>
          <label htmlFor="first_name">First Name</label>
          <input
            type="text"
            id="first_name"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            required
            ref={firstNameRef}
          />
        </div>

        <div className={`form-group ${!formData.last_name && errorMessage ? 'error-field' : ''}`}>
          <label htmlFor="last_name">Last Name</label>
          <input
            type="text"
            id="last_name"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            required
            ref={lastNameRef}
          />
        </div>

        <div className={`form-group ${!formData.email && errorMessage ? 'error-field' : ''}`}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            ref={emailRef}
          />
        </div>

        <PhoneNumberInput
          value={formData.phone_number}
          onChange={handlePhoneChange}
          hasError={!formData.phone_number && !phoneRegex.test(formData.phone_number) && errorMessage}
        />

        <div className={`form-group ${errorMessage && !isValidAge(formData.date_of_birth) ? 'error-field' : ''}`}>
          <label htmlFor="date_of_birth">Date of Birth</label>
          <input
            type="date"
            id="date_of_birth"
            name="date_of_birth"
            value={formData.date_of_birth}
            onChange={handleChange}
            required
            ref={dateOfBirthRef}
          />
        </div>

        <div
          className={`form-group password-group ${
            errorMessage && !passwordRegex.test(formData.password) ? 'error-field' : ''
          }`}
        >
          <label htmlFor="password">Password</label>
          <div className="password-wrapper">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              ref={passwordRef}
            />
            <span
              className="password-toggle-icon"
              onClick={() => setShowPassword(prevShowPassword => !prevShowPassword)}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </span>
          </div>
        </div>

        <div
          className={`form-group password-group ${
            !passwordRegex.test(formData.confirmPassword) && errorMessage ? 'error-field' : ''
          }`}
        >
          <label htmlFor="confirmPassword">Confirm Password</label>
          <div className="password-wrapper">
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              ref={confirmPasswordRef}
            />
            <span
              className="password-toggle-icon"
              onClick={() => setShowConfirmPassword(prevShowConfirmPassword => !prevShowConfirmPassword)}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </span>
          </div>
        </div>

        {errorMessage && <p className="error">{errorMessage}</p>}

        <button type="submit" disabled={!isSubmit}>
          Register
        </button>

        <p>
          Already have an account? <a href="/login">Login</a>
        </p>
      </form>
    </div>
  );
};

export default RegisterForm;
