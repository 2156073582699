import React, { useEffect, useState } from 'react';
import UserCard from '../components/UserCard/UserCard';
import { getUserInfo } from '../api_utils/auth_service';

const HomePage = () => {

  const [user, setUser] = useState(undefined);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userData = await getUserInfo();
        console.log(userData);
        setUser(userData);
      } catch (error) {
        console.error('Failed to fetch user info:', error); // Handle any errors
      }
    };

    fetchUserInfo();
  }, []);

  return(
    <>
    {user && <UserCard user={user}/>}
    </>
  )
};

export default HomePage;
