import api from './api';
import { toast } from 'react-toastify';

export const authTwoFactorUser = async (email, password) => {
  try {
    await api.post('two-factor-auth', { email, password });
  } catch (error) {
    console.error('Error during authTwoFactorUser:', error);
    toast.error('Error during login. Please check your credentials.');
    throw error;
  }
};

export const verifyTwoFactorCodeAndGenerateToken = async (email, code) => {
  try {
    const response = await api.post('verify-2fa', { email, code });
    const token = response.data.token;
    localStorage.setItem('token', token);
    return token;
  } catch (error) {
    console.error('Error during verifyTwoFactorCodeAndGenerateToken:', error);
    toast.error('Error verify code. Please check your credentials.');
    throw error;
  }
};

export const recoveryRequest = async email => {
  try {
    await api.post('recovery-request', { email });
  } catch (error) {
    console.error('Error recoveryRequest:', error);
    toast.error('Recovery request failed. Please try again.');
    throw error;
  }
};

export const validateUserPasswordRecoveryCode = async (email, recovery_code) => {
  try {
    await api.post('validate-recovery-code', { email, recovery_code });
  } catch (error) {
    console.error('Error validateUserPasswordRecoveryCode:', error);
    toast.error('Validate user password recovery code. Please try again.');
    throw error;
  }
};

export const resetUserPassword = async (email, recovery_code, new_password) => {
  try {
    await api.post('password/reset', { email, recovery_code, new_password });
  } catch (error) {
    console.error('Error validateUserPasswordRecoveryCode:', error);
    toast.error('Validate user password recovery code. Please try again.');
    throw error;
  }
};

export const registerUser = async userData => {
  try {
    const response = await api.post('register', userData);
    const token = response.data.token;
    localStorage.setItem('token', token);
    return token;
  } catch (error) {
    const errorMessage =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Registration failed. Please try again.';

    console.error('Error during registerUser:', error);
    toast.error(errorMessage);
    throw error;
  }
};

export const getUserInfo = async () => {
  try {
    const response = await api.get('info/get');
    return response.data;
  } catch (error) {
    console.error('Error during getUserInfo:', error);
    throw error;
  }
};

export const createIndividual = async address => {
  try {
    const response = await api.post('individual/create', address);
    return response.data;
  } catch (error) {
    console.error('Error during createIndividual:', error);
    toast.error('Failed to create individual.');
    throw error;
  }
};

export const getIndividual = async () => {
  try {
    const response = await api.get('individual/get');
    return response.data;
  } catch (error) {
    console.error('Error during getIndividual:', error);
    throw error;
  }
};

export const activateIndividual = async returnUrl => {
  try {
    const response = await api.post('individual/activate', { return_url: returnUrl });
    return response.data;
  } catch (error) {
    console.error('Error during activateIndividual:', error);
    toast.error('Failed to activate individual.');
    throw error;
  }
};

export const createAccount = async () => {
  try {
    const response = await api.post('account/create');
    return response.data;
  } catch (error) {
    console.error('Error during createAccount:', error);
    toast.error('Failed to create account.');
    throw error;
  }
};

export const getAccount = async () => {
  try {
    const response = await api.get('account/get');
    return response.data;
  } catch (error) {
    console.error('Error during getAccount:', error);
    throw error;
  }
};

export const consentBalance = async returnUrl => {
  try {
    const response = await api.post('consents/balance', { return_url: returnUrl });
    return response.data;
  } catch (error) {
    console.error('Error during consentBalance:', error);
    throw error;
  }
};

export const getBankDetails = async () => {
  try {
    const response = await api.get('account/bank-details/get');
    return response.data;
  } catch (error) {
    console.error('Error during getAccount:', error);
    throw error;
  }
};

export const createCard = async orderCardDto => {
  try {
    const response = await api.post('card/create', orderCardDto);
    return response.data;
  } catch (error) {
    console.error('Error during createCard:', error);
    toast.error('Failed to create card.');
    throw error;
  }
};

export const getUserCards = async () => {
  try {
    const response = await api.get('cards/get');
    return response.data;
  } catch (error) {
    console.error('Error during getUserCards:', error);
    throw error;
  }
};

export const simulateOnboardingKYC = async () => {
  try {
    const response = await api.post('test/simulate-onboarding-kyc');
    return response.data;
  } catch (error) {
    console.error('Error during simulateOnboardingKYC:', error);
    throw error;
  }
};

export const simulateOnboardingStatus = async () => {
  try {
    const response = await api.post('test/simulate-onboarding-status');
    return response.data;
  } catch (error) {
    console.error('Error during simulateOnboardingStatus:', error);
    throw error;
  }
};

export const activateCard = async (card_id, return_url) => {
  try {
    const response = await api.post('card/activate', { card_id });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 412) {
      const respCon = await consent(error.response.data.consent.id, return_url);
      console.log('activateCard > ', respCon);
      console.log('activateCard > ', respCon.data);
      return respCon;
    }
    toast.error(error.response.data.message);
    throw error;
  }
};

export const consent = async (consent_id, return_url) => {
  try {
    const response = await api.post('consent', { consent_id, return_url });
    return response.data;
  } catch (error) {
    console.error('Error during activateCard:', error);
    // throw error;
  }
};

export const setCardStatus = async (card_id, status, return_url) => {
  try {
    const response = await api.post('card/set-status', { card_id, status });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 412) {
      const respCon = await consent(error.response.data.consent.id, return_url);
      console.log('activateCard > ', respCon);
      console.log('activateCard > ', respCon.data);
      return respCon;
    }
    console.error('Error during setCardStatus:', error);
    toast.error(error.response.data.message);
    throw error;
  }
};
