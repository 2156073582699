import React, { useEffect, useRef, useState } from 'react';
import './UserCard.css';
import {
  activateIndividual,
  consentBalance,
  createAccount,
  createCard,
  createIndividual,
  getAccount,
  getBankDetails,
  getIndividual,
  getUserCards,
  simulateOnboardingKYC,
  simulateOnboardingStatus,
} from '../../api_utils/auth_service';
import BankCard from '../BankCard/BankCard';

const UserCard = ({ user }) => {
  const [showAddressFields, setShowAddressFields] = useState(false);
  const [address, setAddress] = useState({
    country_code: '',
    street1: '',
    city: '',
    region: '',
    postal_code: '',
  });
  const [errorAddressMessage, setErrorAddressMessage] = useState('');
  const [isFormAddressValid, setIsFormAddressValid] = useState(false);

  const [newCard, setNewCard] = useState({
    display_name: '',
    exp_month: '',
    exp_year: '',
    type: '',
  });

  const [isCardFormValid, setIsCardFormValid] = useState(false);
  const [errorCardMessage, setErrorCardMessage] = useState('');

  const interval = useRef(null);
  const intervalAccount = useRef(null);
  const intervalCards = useRef(null);
  const intervalbank = useRef(null);
  const checkAccountDetailsRef = useRef(null);

  const [individual, setIndividual] = useState(undefined);
  const [account, setAccount] = useState(undefined);
  const [bankDetails, setBankDetails] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const [cards, setCards] = useState([]);
  const [isNeedConcentBalance, setIsNeedConcentBalance] = useState(false);
  const [isKYC, setIsKYC] = useState(false);

  // --------------------------Individual------------------------------------
  useEffect(() => {
    const fetchIndividualInfo = async () => {
      try {
        const individualData = await getIndividual();
        setIndividual(individualData);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    fetchIndividualInfo();
  }, []);

  useEffect(() => {
    const fetchIndividualInfo = async () => {
      try {
        const individualData = await getIndividual();
        setIndividual(individualData);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    if (individual && !interval.current) {
      interval.current = setInterval(() => {
        fetchIndividualInfo();
      }, 3000);
    }
  }, [individual]);

  useEffect(() => {
    const allFieldsFilled =
      address.country_code.trim() &&
      address.street1.trim() &&
      address.city.trim() &&
      address.region.trim() &&
      address.postal_code.trim();

    setIsFormAddressValid(allFieldsFilled);
  }, [address]);

  // --------------------------Account------------------------------------

  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        const accountData = await getAccount();
        setAccount(accountData);
      } catch (error) {
        console.error('Failed to fetch user info:', error.response.data.code);
        if (error.response.data.code === 412) setIsNeedConcentBalance(true);
        setAccount(undefined);
      }
    };

    if(individual && individual.status === 'activated' && !checkAccountDetailsRef.current){
      fetchAccountInfo();
      checkAccountDetailsRef.current = true;
    }
  }, [individual]);

  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        const accountData = await getAccount();
        setAccount(accountData);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    if (account && !intervalAccount.current) {
      intervalAccount.current = setInterval(() => {
        fetchAccountInfo();
      }, 3000);
    }
  }, [account]);

  // --------------------------Banks------------------------------------

  useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const accountData = await getBankDetails();
        setBankDetails(accountData);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    // if(individual && individual.status === 'activated')
    fetchBankDetails();
  }, []);

  useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const accountData = await getBankDetails();
        setBankDetails(accountData);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    if (account && !intervalbank.current) {
      intervalbank.current = setInterval(() => {
        fetchBankDetails();
      }, 3000);
    }
  }, [account]);

  // --------------------------Cards------------------------------------

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const cardsData = await getUserCards();
        setCards(cardsData);
      } catch (error) {
        console.error('Failed to fetch user cards:', error);
      }
    };

    if (bankDetails && !intervalCards.current) {
      intervalCards.current = setInterval(() => {
        fetchCards();
      }, 3000);
    }
  }, [bankDetails]);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const cardsData = await getUserCards();
        setCards(cardsData);
      } catch (error) {
        console.error('Failed to fetch user cards:', error);
      }
    };

    if (cards && !intervalCards.current) {
      intervalCards.current = setInterval(() => {
        fetchCards();
      }, 2000);
    }
  }, [cards]);

  useEffect(() => {
    const allFieldsFilled = newCard.display_name && newCard.exp_month && newCard.exp_year && newCard.type;

    if (allFieldsFilled) {
      const currentDate = new Date();
      const cardExpiryDate = new Date(`${newCard.exp_year}-${newCard.exp_month}-01`);
      const minExpiryDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
      const maxExpiryDate = new Date(currentDate.getFullYear() + 3, currentDate.getMonth() + 1, 1);

      if (cardExpiryDate < minExpiryDate || cardExpiryDate > maxExpiryDate) {
        setErrorCardMessage('Card validity must be between the current date plus one month and three years.');
        setIsCardFormValid(false);
      } else {
        setErrorCardMessage('');
        setIsCardFormValid(true);
      }
    } else {
      setIsCardFormValid(false);
    }
  }, [newCard]);

  useEffect(() => {
    if (individual?.status === 'onboarding_data_provided') setIsKYC(true);
  }, [individual]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const handleShowAddressFields = () => {
    setShowAddressFields(true);
  };

  const handleAddressChange = e => {
    const { name, value } = e.target;
    setAddress({
      ...address,
      [name]: value,
    });
  };

  const handleCardChange = e => {
    const { name, value } = e.target;
    setNewCard({
      ...newCard,
      [name]: value,
    });
  };

  const handleCreateIndividual = async () => {
    try {
      const response = await createIndividual(address);
      console.log('Individual created:', response);
      setIndividual(response);
      setShowAddressFields(false);
      setErrorAddressMessage('');
    } catch (error) {
      console.error('Failed to create individual:', error);
      setErrorAddressMessage('Failed to create individual');
    }
  };

  const handleActivateIndividual = async () => {
    try {
      const response = await activateIndividual(window.location.href);
      console.log('Individual created:', response);
      setIndividual(response);
      window.open(response.redirect_url, '_self');
    } catch (error) {
      console.error('Failed to create individual:', error);
      setErrorMessage('Failed to create individual');
    }
  };

  const handleCreateAccount = async () => {
    try {
      const response = await createAccount();
      console.log('Account created:', response);
      const accountData = await getBankDetails();
      setAccount(accountData);
    } catch (error) {
      console.error('Failed to create account:', error);
      setErrorMessage('Failed to create account');
    }
  };

  const handleConsentBalance = async () => {
    try {
      const response = await consentBalance(window.location.href);
      console.log('handleConsentBalance:', response);
      window.open(response.method.redirect_url, '_self');
    } catch (error) {
      console.error('Failed to create individual:', error);
      setErrorMessage('Failed to create individual');
    }
  };

  const handleCreateCard = async () => {
    if (!isCardFormValid) {
      setErrorCardMessage('Please fill in all required fields and ensure card validity is correct.');
      return;
    }

    try {
      console.log('> handleCreateCard > ', newCard);
      const response = await createCard(newCard);
      console.log('Account created:', response);
    } catch (error) {
      console.error('Failed to create account:', error);
      setErrorMessage('Failed to create account');
    } finally {
      setNewCard({
        display_name: '',
        exp_month: '',
        exp_year: '',
        type: '',
      });
    }
  };

  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 1; i <= 3; i++) {
      years.push(currentYear + i);
    }
    return years;
  };

  const handleSimulateKYC = async () => {
    try {
      const response = await simulateOnboardingKYC();
      console.log('Individual created:', response);
      // setIsKYC(false);
    } catch (error) {
      setErrorMessage('handleSimulateKYC');
    }
  };

  const handleSimulateStatus = async () => {
    try {
      const response = await simulateOnboardingStatus();
      console.log('Individual created:', response);
      setIsKYC(false);
    } catch (error) {
      setErrorMessage('handleSimulateStatus');
    }
  };

  return (
    <>
      <div className="user-card">
        <h2>User Information</h2>
        <div className="user-info">
          <p>
            <strong>First Name:</strong> {user.first_name}
          </p>
          <p>
            <strong>Last Name:</strong> {user.last_name}
          </p>
          <p>
            <strong>Email:</strong> {user.email}
          </p>
          <p>
            <strong>Phone Number:</strong> {user.phone_number}
          </p>
          <p>
            <strong>Date of Birth:</strong> {user.date_of_birth}
          </p>
          {individual && (
            <>
              {individual?.address && (
                <>
                  <p>
                    <strong>Address:</strong>
                  </p>
                  <div className="address-info">
                    <p>
                      <strong>Country Code:</strong> {individual?.address?.country_code}
                    </p>
                    <p>
                      <strong>Street Address:</strong> {individual?.address?.street1}
                    </p>
                    <p>
                      <strong>City:</strong> {individual?.address?.city}
                    </p>
                    <p>
                      <strong>Region:</strong> {individual?.address?.region}
                    </p>
                    <p>
                      <strong>Postal Code:</strong> {individual?.address?.postal_code}
                    </p>
                  </div>
                </>
              )}
              <p>
                <strong>Individual status:</strong> {individual?.status}
              </p>
            </>
          )}
        </div>

        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>

        {!individual && !showAddressFields && (
          <div className="button-block">
            <button className="create-individual-button" onClick={handleShowAddressFields}>
              Create Individual
            </button>
          </div>
        )}

        {individual && individual.status === 'pending_activation' && (
          <div className="button-block">
            <button className="create-individual-button" onClick={handleActivateIndividual}>
              Activate Individual
            </button>
          </div>
        )}

        {isKYC && (
          <div className="button-block">
            <button className="create-individual-button" onClick={handleSimulateKYC}>
              Simulate KYC
            </button>
          </div>
        )}

        {isKYC && (
          <div className="button-block">
            <button className="create-individual-button" onClick={handleSimulateStatus}>
              Simulate status
            </button>
          </div>
        )}

        {showAddressFields && (
          <>
            {/* <p className="optional-hint">! All fields below are optional</p> */}
            <div className="address-form-block">
              <div className="address-form-block">
                <div className="form-group">
                  <label htmlFor="country_code">Country of Registration</label>
                  <select
                    id="country_code"
                    name="country_code"
                    value={newCard.country_code}
                    onChange={handleAddressChange}
                  >
                    <option value="">Select country code</option>
                    {[
                      'AT',
                      'BE',
                      'BG',
                      'HR',
                      'CY',
                      'CZ',
                      'DK',
                      'EE',
                      'FI',
                      'FR',
                      'DE',
                      'GR',
                      'HU',
                      'IE',
                      'IS',
                      'IT',
                      'LV',
                      'LT',
                      'LI',
                      'LU',
                      'MT',
                      'NL',
                      'PL',
                      'PT',
                      'RO',
                      'SK',
                      'SI',
                      'ES',
                      'SE',
                      'NO',
                    ].map(countryCode => (
                      <option key={countryCode} value={countryCode}>
                        {countryCode}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* <div className="form-group">
                <label htmlFor="country_code">Country Code</label>
                <input
                  type="text"
                  id="country_code"
                  name="country_code"
                  value={address.country_code}
                  onChange={handleAddressChange}
                  placeholder="e.g. US"
                />
              </div> */}

              <div className="form-group">
                <label htmlFor="street1">Street Address</label>
                <input
                  type="text"
                  id="street1"
                  name="street1"
                  value={address.street1}
                  onChange={handleAddressChange}
                  placeholder="Street Address"
                />
              </div>

              <div className="form-group">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={address.city}
                  onChange={handleAddressChange}
                  placeholder="City"
                />
              </div>

              <div className="form-group">
                <label htmlFor="region">Region</label>
                <input
                  type="text"
                  id="region"
                  name="region"
                  value={address.region}
                  onChange={handleAddressChange}
                  placeholder="Region"
                />
              </div>

              <div className="form-group">
                <label htmlFor="postal_code">Postal Code</label>
                <input
                  type="text"
                  id="postal_code"
                  name="postal_code"
                  value={address.postal_code}
                  onChange={handleAddressChange}
                  placeholder="Postal Code"
                />
              </div>

              <button className="submit-button" onClick={handleCreateIndividual} disabled={!isFormAddressValid}>
                Create Individual
              </button>

              {errorAddressMessage && <p className="error">{errorAddressMessage}</p>}
            </div>
          </>
        )}
      </div>
      {individual && individual.status === 'activated' && (
        <div className="user-card">
          <h2>Account Information</h2>

          {!bankDetails && (
            <div className="button-block">
              <button className="create-individual-button" onClick={handleCreateAccount}>
                Create account
              </button>
            </div>
          )}

          {isNeedConcentBalance && (
            <div className="button-block">
              <button className="create-individual-button" onClick={handleConsentBalance}>
                Consent to display balance
              </button>
            </div>
          )}

          {account && (
            <>
              {' '}
              <div className="user-info">
                <p>
                  <strong>Name:</strong> {account.name}
                </p>
                <p>
                  <strong>Balance:</strong> {account.balance} {account.currency}
                </p>
                {/* {account?.created_at && (<p>
                  <strong>Created:</strong>{' '}
                  {new Date(account.created_at).toISOString().replace('T', ' ').substring(0, 19)}
                </p>)} */}
              </div>
            </>
          )}

          {bankDetails && (
            <>
              <h2>Banks Information</h2>
              {bankDetails.map((acc, index) => (
                <div key={index} className="user-info">
                  <p>
                    <strong>Beneficiary:</strong> {acc.beneficiary}
                  </p>
                  <p>
                    <strong>BIC:</strong> {acc.details.bic}
                  </p>
                  <p>
                    <strong>IBAN:</strong> {acc.details.iban}
                  </p>
                  <p>
                    <strong>Payment rail:</strong> {acc.payment_rail}
                  </p>
                </div>
              ))}
            </>
          )}
        </div>
      )}

      {console.log(account)}
      {bankDetails && (
        <div className="user-card">
          <h2>Cards Information</h2>

          <div className="cards-container">
            {cards.length > 0 &&
              cards.map((card, index) => (
                <BankCard
                  key={index}
                  brand={card.brand}
                  currency={card.currency}
                  display_name={card.display_name}
                  exp_month={card.exp_month}
                  exp_year={card.exp_year}
                  status={card.status}
                  id={card.id}
                  type={card.type}
                  last4={card.last4}
                  region={card.region}
                />
              ))}
          </div>

          <div className="address-form-block">
            <div className="form-group">
              <label htmlFor="display_name">Display name</label>
              <input
                type="text"
                id="display_name"
                name="display_name"
                value={newCard.display_name}
                onChange={handleCardChange}
                placeholder="Display name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="exp_month">Exp. month</label>
              <select id="exp_month" name="exp_month" value={newCard.exp_month} onChange={handleCardChange}>
                <option value="">Select month</option>
                {[...Array(12)].map((_, i) => (
                  <option key={i + 1} value={(i + 1).toString().padStart(2, '0')}>
                    {(i + 1).toString().padStart(2, '0')}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="exp_year">Exp. year</label>
              <select id="exp_year" name="exp_year" value={newCard.exp_year} onChange={handleCardChange}>
                <option value="">Select year</option>
                {generateYears().map(year => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="type">Type</label>
              <select id="type" name="type" value={newCard.type} onChange={handleCardChange}>
                <option value="">Select type</option> {/* Значення за замовчуванням */}
                <option value="virtual">Virtual</option>
                <option value="physical">Physical</option>
              </select>
            </div>
          </div>

          {/* {!account && ( */}
          <div className="button-block">
            <button className="create-individual-button" onClick={handleCreateCard} disabled={!isCardFormValid}>
              Create card
            </button>
          </div>

          {errorCardMessage && <p className="error">{errorCardMessage}</p>}

          {/* )} */}

          {/* {account && (
            <>
              <h2>Banks Information</h2>
              {account.map((acc, index) => (
                <div key={index} className="user-info">
                  <p>
                    <strong>Beneficiary:</strong> {acc.beneficiary}
                  </p>
                  <p>
                    <strong>BIC:</strong> {acc.details.bic}
                  </p>
                  <p>
                    <strong>IBAN:</strong> {acc.details.iban}
                  </p>
                  <p>
                    <strong>Payment rail:</strong> {acc.payment_rail}
                  </p>
                </div>
              ))}
            </>
          )} */}
        </div>
      )}
    </>
  );
};

export default UserCard;
