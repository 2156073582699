import React, { useEffect, useRef, useState } from 'react';
import './BankCard.css';
import { activateCard, setCardStatus } from '../../api_utils/auth_service';

const BankCard = props => {
  const { brand, currency, display_name, exp_month, exp_year, status, id, type, last4, region } = props;

  const selectRef = useRef(null);

  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const [selectedStatus, setSelectedStatus] = useState(status);
  const [isEditingStatus, setIsEditingStatus] = useState(false);

  useEffect(() => {
    console.log('status = ', status);
    setSelectedStatus(status);
  }, [status]);

  const handleStatusChange = async event => {
    event.stopPropagation();
    setSelectedStatus(event.target.value);
    setIsEditingStatus(false);

    if (event.target.value === 'active') {
      try {
        const response = await activateCard(id, window.location.href);
        console.log('activateCard: ', response);
        if (response?.method?.redirect_url) {
          window.open(response.method.redirect_url, '_self');
        }
      } catch (error) {
        setSelectedStatus(status);
      }
    } else {
      try {
        const response = await setCardStatus(id, event.target.value);
        if (response?.method?.redirect_url) {
          window.open(response.method.redirect_url, '_self');
        }
      } catch (error) {
        setSelectedStatus(status);
      }
    }
  };

  const handleStatusClick = event => {
    event.stopPropagation();
    setIsEditingStatus(true);

    setTimeout(() => {
      if (selectRef.current) {
        selectRef.current.focus();
        selectRef.current.size = statusOptions.length;
      }
    }, 0);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsEditingStatus(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const statusOptions = ['active', 'frozen', 'blocked', 'stolen', 'lost', 'fraud', 'not_activated', 'security_block'];

  return (
    <div className={`card-container ${isFlipped ? 'flipped' : ''}`} onClick={handleFlip}>
      <div className="card">
        <div className="card-front">
          <div className="card-header">
            <span className="brand">{brand.toUpperCase()}</span>
            {/* <span className="chip"></span> */}
            <span className="card-type">{type.toUpperCase()}</span>
          </div>
          <div className="card-number">
            <span>**** **** **** {last4}</span>
          </div>
          <div className="card-info">
            <div className="status-info" onClick={handleStatusClick}>
              <span className="label">Status:</span>{' '}
              {isEditingStatus ? (
                <select
                  className="select-upwards"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  ref={selectRef}
                  onBlur={() => (selectRef.current.size = 0)}
                  onClick={e => e.stopPropagation()}
                >
                  {statusOptions.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <span className="status-display">{selectedStatus}</span>
              )}
              <span className="status-hint">(Click on the status to change)</span>
            </div>
            <div className="card-footer">
              <div>
                <span className="label">Exp:</span> {exp_month}/{exp_year}
              </div>
              <div>
                <span className="display-name">{display_name}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-back">
          <div>
            <span className="label">Region:</span> {region}
          </div>
          <div>
            <span className="label">ID:</span> {id}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankCard;
