import React from 'react';
import './Layout.css';

import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <div className="layout-container">
      <header className="layout-header">
        <div className="logo-container">
          <img src="/9063313.png" alt="Bank Cards Logo" className="logo" />
          <h1>Bank Cards</h1>
        </div>
      </header>

      <Outlet />

      <footer className="layout-footer">
        <p>&copy; 2024 Bank Cards Log. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Layout;
